import { yupResolver } from "@hookform/resolvers";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Axios from "axios";
import { observer } from "mobx-react";
import React, { Fragment } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import FormInputPassword from "./form/FormInputPassword";
import SnackBarNotifier from "./SnackBarNotifier";

const DialogChangePassword = (props) => {
  const { viewModel } = props;

  let validationSchema = yup.object().shape({
    current_password: yup.string().required("Current Password is required"),
    password: yup.string().required("New Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, errors } = methods;

  const [updating, setUpdating] = React.useState(false);

  const onSubmit = (data) => {
    setUpdating(true);
    Axios.post(
      `/portal/password?current=${data.current_password}&newpwd=${data.password}`
    )
      .then((res) => {
        viewModel.showSnackbarSuccess("Change password succesfully");
        props.onSuccess();
      })
      .catch((err) => {
        viewModel.showSnackbarError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  return (
    <Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Change Password"}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <form>
              <div>
                <FormInputPassword
                  name="current_password"
                  label="Current Password"
                  required={true}
                  errorobj={errors}
                  defaultValue={""}
                  style={{ width: "100%" }}
                />
                <FormInputPassword
                  name="password"
                  label="New Password"
                  required={true}
                  errorobj={errors}
                  defaultValue={""}
                  style={{ width: "100%" }}
                />
                <FormInputPassword
                  name="confirmPassword"
                  label="Confirm Password"
                  required={true}
                  errorobj={errors}
                  defaultValue={""}
                  style={{ width: "100%" }}
                />
              </div>
            </form>
          </FormProvider>
        </DialogContent>
        {updating ? (
          <DialogActions>
            <center>
              <CircularProgress />
            </center>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={props.onCancelClick} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit(onSubmit)} color="secondary">
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <SnackBarNotifier viewModel={viewModel} />
    </Fragment>
  );
};

export default observer(DialogChangePassword);
