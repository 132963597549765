import { autorun } from "mobx";
import { observer } from "mobx-react";
import { withSnackbar } from "notistack";
import { Component } from "react";

class SnackBarNotifier extends Component {
  displayed = [];

  storeDisplayed = (id) => {
    this.displayed = [...this.displayed, id];
  };

  componentDidMount() {
    autorun(() => {
      this.props.viewModel.notifications.forEach((notification) => {
        // Do nothing if snackbar is already displayed
        if (this.displayed.includes(notification.key)) return;
        // Display snackbar using notistack
        this.props.enqueueSnackbar(notification.message, notification.options);
        // Keep track of snackbars that we've displayed
        this.storeDisplayed(notification.key);
        // Dispatch action to remove snackbar from mobx store
        this.props.viewModel.removeSnackbar(notification.key);
      });
    });
  }

  render() {
    return null;
  }
}

export default withSnackbar(observer(SnackBarNotifier));
