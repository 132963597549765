/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { Fade, Modal, makeStyles } from "@material-ui/core";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage, Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fade: {
    backgroundColor: "#fff",
    border: "2px solid #000",
    padding: theme.spacing(2, 4),
  },
}));

export function Routes() {
  const classes = useStyles();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const { isAdmin, userRole } = useSelector(({ auth }) => ({
    isAdmin: ["SUPERVISOR", "ADMIN"].includes(auth.role),
    userRole: auth.role,
  }));

  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  let urlWhitelist = [
    "/odd/cancel-bet-manager/",
    "/bets/parlay-detail/",
    "/auth/login",
  ];
  const shouldOpenLogin = () => {
    let openLogin = true;
    if (window.performance.getEntriesByType("navigation")) {
      let p = window.performance.getEntriesByType("navigation")[0].type;
      console.log(p + "  -  " + window.location.href);
      if (p === "navigate") {
        urlWhitelist.forEach((item, index) => {
          if ((window.location.href + "").includes(item)) {
            openLogin = false;
          }
        });

        if (openLogin) {
          localStorage.clear();
        }
      } else {
        openLogin = false;
      }
    } else {
      openLogin = false;
    }
    return openLogin;
  };

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Switch>
          {(() => {
            if (isAdmin) {
              return (
                <Layout>
                  <BasePage shouldOpenLogin={shouldOpenLogin()} />
                </Layout>
              );
            } else {
              return open ? (
                <Modal
                  open={open}
                  onClose={handleClose}
                  className={classes.modal}
                >
                  <Fade in={open}>
                    <div className={classes.fade}>Log out upon closing.</div>
                  </Fade>
                </Modal>
              ) : (
                <>
                  <Redirect to="/logout" />
                </>
              );
            }
          })()}
        </Switch>
      )}
    </Switch>
  );
}
