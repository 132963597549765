import {
  Divider,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Fragment } from "react";
import EmptyPage from "../../../component/EmptyPage";
import EnhancedTableHead from "../../../component/EnhancedTableHead";
import TableButtonAction from "../../../component/TableButtonAction";
import TableToolbar from "../../../component/TableToolbar";

// START TABLE HEAD
const headCells = [
  { id: "number", align: "center", disablePadding: true, label: "NO" },
  { id: "code", align: "center", disablePadding: false, label: "CODE" },
  {
    id: "provider",
    align: "center",
    disablePadding: false,
    label: "PROVIDER",
  },
  {
    id: "statusSwitchProvider",
    align: "center",
    disablePadding: false,
    label: "STATUS SWITCH PROVIDER",
  },
  {
    id: "early",
    align: "center",
    disablePadding: false,
    label: "EARLY",
  },
  {
    id: "today",
    align: "center",
    disablePadding: false,
    label: "TODAY",
  },
  {
    id: "running",
    align: "center",
    disablePadding: false,
    label: "RUNNING",
  },
  {
    id: "parlay",
    align: "center",
    disablePadding: false,
    label: "PARLAY",
  },
  {
    id: "quick",
    align: "center",
    disablePadding: false,
    label: "QUICK",
  },
  { id: "action", align: "center", disablePadding: false, label: "ACTION" },
];
// END TABLE HEAD

const CloseMarketList = (props) => {
  const viewModel = props.viewModel;

  return (
    <Fragment>
      <TableContainer component={Paper} style={{ height: "100%" }}>
        <TableToolbar title={"CloseMarket"} />
        <Divider />
        {viewModel.models.length === 0 ? (
          <EmptyPage icon={"volume_up_rounded"} text={"Empty CloseMarket"} />
        ) : (
          <>
            <Table size="small" aria-label="a dense table">
              <EnhancedTableHead
                headCells={headCells}
                order={null}
                orderBy={null}
                onRequestSort={null}
                rowCount={viewModel.models.size}
              />
              <TableBody>
                {viewModel.models.map((model, i) => (
                  <TableRow key={model.pubId}>
                    <TableCell align="center">
                      <b>{i}</b>
                    </TableCell>
                    <TableCell align="center">{model.code}</TableCell>
                    <TableCell align="center">{model.provider}</TableCell>
                    <TableCell align="center">{model.status}</TableCell>
                    <TableCell align="center">
                      {model.early ? <Icon>check</Icon> : <></>}
                    </TableCell>
                    <TableCell align="center">
                      {model.today ? <Icon>check</Icon> : <></>}
                    </TableCell>
                    <TableCell align="center">
                      {model.running ? <Icon>check</Icon> : <></>}
                    </TableCell>
                    <TableCell align="center">
                      {model.parlay ? <Icon>check</Icon> : <></>}
                    </TableCell>
                    <TableCell align="center">
                      {model.quick ? <Icon>check</Icon> : <></>}
                    </TableCell>
                    <TableCell align="center">
                      <TableButtonAction
                        tooltip="Edit"
                        iconName="edit"
                        onClick={() => {
                          viewModel.setModelWillEdit(model);
                          viewModel.showPageEdit(true);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
    </Fragment>
  );
};

export default observer(CloseMarketList);
