import React, { Fragment, useEffect, useState } from "react";
import DialogConfirmation from "../../../component/DialogConfirmation";
import axios from "axios";
import SnackBarNotifier from "../../../component/SnackBarNotifier";

const HeaderItemSubMenuLogoutPlayer = (props) => {
  const { viewModel } = props;

  const [open, setOpen] = useState(false);

  const onSubmit = () => {
    axios.post(
      `/admin/settings/logoutAllPlayers`
    )
      .then((res) => {
        setOpen(false);
        viewModel.showSnackbarSuccess("Force logout all players succesfully");
      })
      .catch((err) => {
        viewModel.showSnackbarError(err);
      });
  };

  return (
    <Fragment>
      <li className={`menu-item`} onClick={() => {
        setOpen(true)
      }}>
        <div className="menu-link">
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">{props.title}</span>
        </div>
      </li>
      <DialogConfirmation
        open={open === true}
        title={"Force Logout All Players"}
        desc="Are you sure to continue?"
        yesButton="YES"
        noButton="NO"
        onYesClicked={() => {
          onSubmit();
        }}
        onNoClicked={() => {
          setOpen(false);
        }}
      />

      <SnackBarNotifier viewModel={viewModel} />
    </Fragment>



  );
};

export default HeaderItemSubMenuLogoutPlayer;
