import { action, observable } from "mobx";
import { BaseViewModel } from "../../component/BaseViewModel";

export class CloseMarketViewModel extends BaseViewModel {
  //================Models=====================
  @observable
  models = [];

  @action
  getData() {
    this.models = [
      {
        code: "100",
        provider: "188Bet",
        status: "Done",
        early: true,
        today: true,
        running: true,
        parlay: true,
        quick: true,
      },
      {
        code: "101",
        provider: "188Bet",
        status: "Done",
        early: true,
        today: true,
        running: true,
        parlay: true,
        quick: true,
      },
      {
        code: "102",
        provider: "188Bet",
        status: "Done",
        early: true,
        today: true,
        running: true,
        parlay: true,
        quick: true,
      },
    ];
    return this.models;
  }

  //================Edit Page=====================

  @observable
  isPageEditShow = false;

  @action
  showPageEdit(isShow) {
    this.isPageEditShow = isShow;
  }

  @observable
  modelWillEdit = null;

  @action
  setModelWillEdit(model) {
    this.modelWillEdit = model;
  }

  @observable
  providers = ["188Bet", "189Bet", "190Bet"];
}
