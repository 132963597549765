import DateFnsAdapter from "@date-io/date-fns";
import { observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import SnackBarNotifier from "../../component/SnackBarNotifier";
import CloseMarketList from "../CloseMarket/widget/CloseMarketList";
import { CloseMarketViewModel } from "./CloseMarketViewModel";
import CloseMarketEdit from "./widget/CloseMarketEdit";

class CloseMarket extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new CloseMarketViewModel();
    this.viewModel.getData();
    this.dateFns = new DateFnsAdapter();
  }

  render() {
    if (this.viewModel.isPageEditShow) {
      return (
        <CloseMarketEdit
          viewModel={this.viewModel}
          onBackPressed={() => {
            this.viewModel.showPageEdit(false);
          }}
        />
      );
    } else {
      return (
        <Fragment>
          {/* start content list */}
          <CloseMarketList viewModel={this.viewModel} />
          {/* end content list */}

          <SnackBarNotifier viewModel={this.viewModel} />
        </Fragment>
      );
    }
  }
}

export default observer(CloseMarket);
