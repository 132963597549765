import { actionTypes } from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {
  //set default API URL
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken && config.baseURL === process.env.REACT_APP_API_URL) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  // response parse
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        console.log("LOGOUT");
        store.dispatch({ type: actionTypes.Logout });
      }
      return Promise.reject(error);
    }
  );
}
