import { yupResolver } from "@hookform/resolvers";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import FormInput from "../../../component/form/FormInput";
import FormSelect from "../../../component/form/FormSelect";
import TableToolbar from "../../../component/TableToolbar";

const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(1),
  },
  denseCheckBox: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(1),
    marginTop: 4,
  },
  cardContent: {
    paddingTop: 0,
    paddingRight: 24,
    paddingLeft: 24,
    marginTop: 0,
  },
}));

const CloseMarketEdit = (props) => {
  const classes = useStyles();
  const viewModel = props.viewModel;

  let validationSchema = yup.object().shape({
    code: yup.string().required("Code is required"),
    provider: yup.string().required("Provider is required"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { register, handleSubmit, errors } = methods;

  const onSubmit = (data) => {
    console.log(data);
    props.onBackPressed();
  };

  return (
    <Card>
      <TableToolbar
        title={"Edit"}
        showBackButton
        onBackPress={() => {
          props.onBackPressed();
        }}
      />

      <Divider />

      <CardContent className={classes.cardContent}>
        <FormProvider {...methods}>
          <form>
            <FormInput
              name="code"
              label="Code"
              required={true}
              errorobj={errors}
              className={classes.dense}
              disabled
              defaultValue={
                viewModel.modelWillEdit != null
                  ? viewModel.modelWillEdit.code
                  : ""
              }
            />

            <div className={classes.dense}>
              <FormSelect
                name="provider"
                label="Provider"
                options={viewModel.providers.map((model) => (
                  <MenuItem key={model} value={model}>
                    {model}
                  </MenuItem>
                ))}
                defaultValue={
                  viewModel.modelWillEdit != null
                    ? viewModel.modelWillEdit.provider
                    : null
                }
                required={true}
                errorobj={errors}
              />
            </div>

            <FormGroup className={classes.denseCheckBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="early"
                    defaultChecked={
                      viewModel.modelWillEdit != null &&
                        viewModel.modelWillEdit.early
                        ? true
                        : true
                    }
                  />
                }
                label="Early Flag"
                inputRef={register}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="today"
                    defaultChecked={
                      viewModel.modelWillEdit != null &&
                        viewModel.modelWillEdit.today
                        ? true
                        : false
                    }
                  />
                }
                label="Today Flag"
                inputRef={register}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="running"
                    defaultChecked={
                      viewModel.modelWillEdit != null &&
                        viewModel.modelWillEdit.running
                        ? true
                        : false
                    }
                  />
                }
                label="Running Flag"
                inputRef={register}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="parlay"
                    defaultChecked={
                      viewModel.modelWillEdit != null &&
                        viewModel.modelWillEdit.parlay
                        ? true
                        : false
                    }
                  />
                }
                label="Parlay Flag"
                inputRef={register}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="quick"
                    defaultChecked={
                      viewModel.modelWillEdit != null &&
                        viewModel.modelWillEdit.quick
                        ? true
                        : false
                    }
                  />
                }
                label="Quick Flag"
                inputRef={register}
              />
            </FormGroup>
          </form>
        </FormProvider>

        {viewModel.isSubmiting === true ? (
          <CircularProgress />
        ) : (
          <Button
            name="btnSubmit"
            variant="contained"
            color="secondary"
            className={classes.button}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <span>Submit</span>
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default observer(CloseMarketEdit);
