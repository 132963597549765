import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const MuiSelect = (props) => {
  const { label, name, options, errorobj, hideemptyoption } = props;
  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  return (
    <>
      <FormControl fullWidth={true} error={isError} {...props}>
        <InputLabel>{label}</InputLabel>
        <Select id={name} {...props}>
          {hideemptyoption != "true" ?
            <MenuItem value="">
              <em>None</em>
            </MenuItem> : null}
          {options}
        </Select>
      </FormControl>
      <FormHelperText>{errorMessage}</FormHelperText>
    </>
  );
};

function FormSelect(props) {
  const { control } = useFormContext();
  const { name, label, hideemptyoption } = props;
  return (
    <React.Fragment>
      <Controller
        as={MuiSelect}
        control={control}
        name={name}
        label={label}
        defaultValue=""
        hideemptyoption={hideemptyoption}
        {...props}
      />
    </React.Fragment>
  );
}

export default FormSelect;
