import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import PagePath from "../res/values/PagePath";
import { Logout } from "./modules/Auth";
import CloseMarket from "./pages/CloseMarket/CloseMarket";

const Setup = lazy(() => import("./pages/Setup/Setup"));
const OddBase = lazy(() => import("./pages/OddBase/OddBase"));
const Bets = lazy(() => import("./pages/Bets/Bets"));
const Result = lazy(() => import("./pages/Result/Result"));
const AnnouncementBase = lazy(() =>
  import("./pages/AnnouncementBase/AnnouncementBase")
);
const Spider = lazy(() => import("./pages/Spider/Spider"));
const Blacklist = lazy(() => import("./pages/Blacklist/Blacklist"));
const AdminBase = lazy(() => import("./pages/AdminBase/AdminBase"));

function BasePage(props) {
  if (props.shouldOpenLogin) {
    return <Redirect exact from="/" to={"/logout"} />;
  } else {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Route path={PagePath.SETUP} component={Setup} />
          <Route path={PagePath.ODDS_BASE} component={OddBase} />
          <Route path={PagePath.BETS} component={Bets} />
          <Route path={PagePath.RESULT} component={Result} />
          <ContentRoute
            path={PagePath.ANNOUNCEMENT_BASE}
            component={AnnouncementBase}
          />
          <Route path={PagePath.SPIDER} component={Spider} />
          <Route path={PagePath.BLACKLIST} component={Blacklist} />

          <Route path={PagePath.ADMINBASE} component={AdminBase} />
          <Route path={PagePath.CLOSE_MARKET} component={CloseMarket} />
          <Route path={"/logout"} component={Logout} />

          {props.shouldOpenLogin ? (
            <Redirect exact from="/" to={"/logout"} />
          ) : (
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to={PagePath.SETUP} />
          )}

          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    );
  }
}
export default connect(null, auth.actions)(BasePage);
