import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

const HeaderItemSubMenu = (props) => {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  return (
    <li className={`menu-item ${getMenuItemActive(props.path)}`}>
      <NavLink className="menu-link" to={props.path}>
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">{props.title}</span>
      </NavLink>
    </li>
  );
};

export default HeaderItemSubMenu;
