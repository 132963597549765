import { Grid, Icon, withStyles } from "@material-ui/core";
import React from "react";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  icon: {
    fontSize: 128,
    color: "#cccccc",
  },
  text: {
    color: "#cccccc",
    marginTop: 36,
    marginBottom: 36,
  },
});

const EmptyPage = (props) => {
  const { classes } = props;
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Icon className={classes.icon}>{props.icon}</Icon>
      <h3 className={classes.text}>{props.text}</h3>
    </Grid>
  );
};

export default withStyles(styles)(EmptyPage);
