import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

function FormInput(props) {
  const { control } = useFormContext();
  const { name, label, required, errorobj, defaultValue } = props;
  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  return (
    <Controller
      as={TextField}
      name={name}
      control={control}
      defaultValue={defaultValue}
      label={label}
      fullWidth={true}
      error={isError}
      helperText={errorMessage}
      {...props}
    />
  );
}

export default observer(FormInput);
