/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import PagePath from "../../../../../res/values/PagePath";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* begin SPIDER */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            PagePath.SPIDER,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.SPIDER}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/clipboard-list.svg"
                )}
              />
            </span>
            <span className="menu-text">SPIDER</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">SPIDER</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.SPIDER_TASK
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={PagePath.SPIDER_TASK}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Task</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        {/* end SPIDER */}

        {/* begin MESSAGES */}
        <li className={`menu-item ${getMenuItemActive(PagePath.ANNOUNCEMENT)}`}>
          <NavLink className="menu-link" to={PagePath.ANNOUNCEMENT}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Media/volume-full.svg")}
              />
            </span>
            <span className="menu-text">ANNOUNCEMENT</span>
          </NavLink>
        </li>
        {/* end MESSAGES */}

        {/* begin REPORT */}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            PagePath.REPORT,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.REPORT}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/clipboard-list.svg"
                )}
              />
            </span>
            <span className="menu-text">REPORT</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">REPORT</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.REPORT_PAGE_1
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={PagePath.REPORT_PAGE_1}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Report Page 1</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.REPORT_PAGE_2
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={PagePath.REPORT_PAGE_2}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Report Page 2</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li> */}
        {/* end REPORT */}

        {/* begin MEMBER INFORMATION */}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            PagePath.MEMBER_INFORMATION,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to={PagePath.MEMBER_INFORMATION}
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/user.svg")} />
            </span>
            <span className="menu-text">MEMBER INFORMATION</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">MEMBER INFORMATION</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.MEMBER_INFORMATION_PAGE_1
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to={PagePath.MEMBER_INFORMATION_PAGE_1}
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Page 1</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.MEMBER_INFORMATION_PAGE_2
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to={PagePath.MEMBER_INFORMATION_PAGE_2}
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Page 2</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li> */}
        {/* end MEMBER INFORMATION */}

        {/* begin BET LISTS */}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            PagePath.BET_LISTS,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.BET_LISTS}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/clipboard-check.svg"
                )}
              />
            </span>
            <span className="menu-text">BET LISTS</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">BET LISTS</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.BET_LISTS_PAGE_1
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={PagePath.BET_LISTS_PAGE_1}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Page 1</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.BET_LISTS_PAGE_2
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={PagePath.BET_LISTS_PAGE_2}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Page 2</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li> */}
        {/* end BET LISTS */}

        {/* begin BET AND FORECAST */}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            PagePath.BETS_AND_FORECAST,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to={PagePath.BETS_AND_FORECAST}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/chart-line1.svg")}
              />
            </span>
            <span className="menu-text">BETS AND FORECAST</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">BETS AND FORECAST</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.BETS_AND_FORECAST_PAGE_1
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to={PagePath.BETS_AND_FORECAST_PAGE_1}
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Page 1</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.BETS_AND_FORECAST_PAGE_2
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to={PagePath.BETS_AND_FORECAST_PAGE_2}
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Page 2</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li> */}
        {/* end BET AND FORECAST */}

        {/* begin TRANSFER */}
        {/* <li className={`menu-item ${getMenuItemActive(PagePath.TRANSFER)}`}>
          <NavLink className="menu-link" to={PagePath.TRANSFER}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/dollar.svg")}
              />
            </span>
            <span className="menu-text">TRANSFER</span>
          </NavLink>
        </li> */}
        {/* end TRANSFER */}

        {/* begin VIEW LOG */}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            PagePath.VIEW_LOG,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.VIEW_LOG}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Files/selected-file.svg")}
              />
            </span>
            <span className="menu-text">VIEW LOG</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">VIEW LOG</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.VIEW_LOG_PAGE_1
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={PagePath.VIEW_LOG_PAGE_1}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Page 1</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    PagePath.VIEW_LOG_PAGE_2
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={PagePath.VIEW_LOG_PAGE_2}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Page 2</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li> */}
        {/* end VIEW LOG */}

        {/* begin ANNOUNCEMENT*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(PagePath.ANNOUNCEMENT)}`}
          aria-haspopup="true"
          data-menu-toogle="hover"
        >
          <NavLink className="menu-link" to={PagePath.ANNOUNCEMENT}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Media/volume-full.svg")}
              />
            </span>
            <span className="menu-text">ANNOUNCEMENTS</span>
          </NavLink>
        </li> */}
        {/* end ANNOUNCEMENT*/}

        {/* begin CHATBOT*/}
        {/* <li className={`menu-item ${getMenuItemActive(PagePath.CHATBOT)}`}>
          <NavLink className="menu-link" to={PagePath.CHATBOT}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/chat4.svg")}
              />
            </span>
            <span className="menu-text">CHATBOT</span>
          </NavLink>
        </li> */}
        {/* end CHATBOT*/}

        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
