import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    width: 24,
  },
}));

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, headCells, colSpan } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ height: 42 }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.id === "number" ? classes.number : null}
            colSpan={colSpan}
          >
            {/* {headCell.id !== "action" ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )} */}
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
      {props.children}
    </TableHead>
  );
};

// EnhancedTableHead.propTypes = {
//   headCells: PropTypes.array.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(["asc", "desc"]).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   colSpan: PropTypes.number,
// };

EnhancedTableHead.defaultProps = {
  colSpan: 1,
};

// END TABLE HEAD

export default EnhancedTableHead;
