import { Icon, IconButton, Tooltip } from "@material-ui/core";
import React from "react";

const TableButtonAction = (props) => {
  return (
    <Tooltip title={props.tooltip} aria-label={props.tooltip}>
      <IconButton
        color="secondary"
        size={"small"}
        onClick={props.onClick}
        tooltip={props.tooltip}
        disabled={props.disabled}
      >
        <Icon>{props.iconName}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default TableButtonAction;
