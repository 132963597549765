import { Icon, IconButton, Toolbar, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import React from "react";
import { useHistory } from "react-router-dom";

const CustomToolbar = withStyles({
  regular: {
    minHeight: 50,
  },
})(Toolbar);

let useStyles = makeStyles((theme) => ({
  titleToolbar: {
    flex: "1 1 100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const TableToolbar = (props) => {
  let classes = useStyles();
  let history = useHistory();
  return (
    <CustomToolbar>
      {props.showBackButton ? (
        <IconButton
          className={classes.menuButton}
          edge="start"
          color="inherit"
          onClick={
            props.onBackPress
              ? props.onBackPress
              : () => {
                  history.goBack();
                }
          }
        >
          <Icon>arrow_back</Icon>
        </IconButton>
      ) : null}
      <Typography
        className={classes.titleToolbar}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {props.title}
      </Typography>
      {props.children}
    </CustomToolbar>
  );
};

export default TableToolbar;
