import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const DialogConfirmation = (props) => {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      {props.children}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.desc}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onNoClicked} color="secondary">
          {props.noButton}
        </Button>
        <Button onClick={props.onYesClicked} color="secondary" autoFocus>
          {props.yesButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
