import { action, observable } from "mobx";

export class BaseViewModel {
  @observable
  notifications = [];

  @action
  showSnackbarSuccess(messages) {
    console.log("hahahaha");
    this.enqueueSnackbar({
      message: messages,
      options: {
        variant: "success",
        autoHideDuration: 2000,
      },
    });
  }

  @action
  showSnackbarError(error) {
    let messages = "error";

    if (error !== undefined && error.response !== undefined) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      messages = "(" + error.response.status + ")";
      if (error.response.data !== undefined) {
        let errMessage = error.response.data.message;
        this.errorCodes.forEach((model) => {
          if (model.code === errMessage) {
            errMessage = model.message;
            return;
          }
        });

        messages = messages + " " + errMessage;
      }
    }

    try {
      this.enqueueSnackbar({
        message: messages,
        options: {
          variant: "error",
          autoHideDuration: 3000,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  @action
  showSnackbarWarning(messages) {
    this.enqueueSnackbar({
      message: messages,
      options: {
        variant: "warning",
        autoHideDuration: 3000,
      },
    });
  }

  @action
  enqueueSnackbar(note) {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      ...note,
    });
  }

  @action
  removeSnackbar(key) {
    this.notifications = this.notifications.filter(
      (notification) => notification.key !== key
    );
  }

  getTextContentById(id) {
    return document.getElementById(id).textContent;
  }

  getValueById(id) {
    return document.getElementById(id).value;
  }

  errorCodes = [
    { message: "USERNAME_NOT_UNIQUE", code: "ADM001" },
    { message: "RESULT_NOT_FOUND", code: "ARES001" },
    { message: "CANCEL_RESULT_ALREADY_CALCULATED", code: "ARES002" },
    { message: "BET_NOT_FOUND", code: "ABET001" },
    { message: "PARLAY_NOT_FOUND", code: "ABET002" },
    { message: "ACCEPT_REJECT_BET_ALREADY_REJECTED", code: "ABET101" },
    { message: "ACCEPT_REJECT_BET_ALREADY_CALCULATED", code: "ABET102" },
    { message: "ACCEPT_REJECT_BET_ALREADY_ACCEPTED", code: "ABET103" },
    { message: "ACCEPT_REJECT_BET_INVALID_TYPE", code: "ABET110" },
    { message: "ROLLBACK_NO_CHANGE", code: "RB001" },
    { message: "ROLLBACK_NOT_AVAILABLE", code: "RB002" },
    { message: "ROLLBACK_FAILED", code: "RB999" },
    { message: "DOMAIN_NOT_FOUND", code: "ADD001" },
    { message: "INCOMPLETE_PARAM", code: "ADD002" },
    { message: "INVALID_IP", code: "ADD003" },
    { message: "INVALID_ADMIN", code: "ARP001" },
    { message: "INVALID_SUPERVISOR", code: "ARP002" },
    { message: "INVALID_SUPERVISOR_PWD", code: "ARP003" },
    { message: "ERROR_DISABLE_ADMIN", code: "ADA001" },
    { message: "ERROR_ENABLE_ADMIN", code: "ADA002" },
    { message: "INVALID_DOWNLINE_ID", code: "AG800" },
    { message: "INVALID_UPLINE_PWD", code: "AG801" },
    { message: "INPUT_VALIDATION", code: "AG000" },
    { message: "USERNAME_NOT_UNIQUE", code: "AG001" },
    { message: "INVALID_USERNAME_FORMAT", code: "AG002" },
    { message: "UNAUTHORISED", code: "AG003" },
    { message: "INSUFFICIENT_CREDIT", code: "AG004" },
    { message: "UNAUTHORISED_COPY", code: "AG005" },
    { message: "UNAUTHORISED_ROLE", code: "AG006" },
    { message: "INVALID_COPY_ROLE", code: "AG007" },
    { message: "INSUFFICIENT_UPLINE_BALANCE", code: "AG008" },
    { message: "INSUFFICIENT_COMMISSION", code: "AG009" },
    { message: "INVALID_COMM_WITHDRAWAL_TYPE", code: "AG010" },
    { message: "INVALID_AGENT_SETTING_LIMIT", code: "AG100" },
    { message: "INVALID_PLAYER_SETTING_LIMIT", code: "AG101" },
    { message: "INVALID_PASSWORD", code: "AG200" },
    { message: "AGENT_NOT_FOUND", code: "AG400" },
    { message: "MEMBER_NOT_FOUND", code: "AG401" },
    { message: "AGENT_SETTING_NOT_FOUND", code: "AG402" },
    { message: "ERROR_DISABLE_DOWNLINE", code: "AG501" },
    { message: "ERROR_ENABLE_DOWNLINE", code: "AG502" },
    { message: "AGENT_CREATION_NOT_ALLOWED", code: "AG601" },
    { message: "USER_CREATION_NOT_ALLOWED", code: "AG602" },
    { message: "SYSTEM_SETTING_DUPLICATED_ERROR", code: "AG603" },
    { message: "SYSTEM_SETTING_INVALID", code: "AG604" },
    { message: "OTHER_VALIDATION", code: "AG999" },
    { message: "INVALID_CREDENTIAL", code: "AUTH000" },
    { message: "WRONG_PASSWORD", code: "AUTH001" },
    { message: "DISABLED_USER", code: "AUTH002" },
    { message: "EMPTY_PASSWORD", code: "AUTH003" },
    { message: "INVALID_FORMAT_PASSWORD", code: "AUTH004" },
    { message: "EXCEED_MAX_CONSECUTIVE_FAILED_LOGIN", code: "AUTH005" },
    { message: "MEMBER_NOT_FOUND", code: "MW001" },
    { message: "AGENT_NOT_FOUND", code: "MW002" },
    { message: "INVALID_DOWNLINE", code: "MW003" },
    { message: "INSUFFICIENT_BALANCE", code: "MW004" },
    { message: "INVALID_OTP", code: "MW005" },
    { message: "WITHDRAWAL_RESPOND_NULL", code: "MW006" },
    { message: "PLAYER_NOT_FOUND", code: "AP000" },
    { message: "INVALID_SESSION", code: "AP001" },
    { message: "VALID_PLAYER_NOT_FOUND", code: "AP002" },
    { message: "PASSWORD_EXPIRED", code: "AP003" },
    { message: "BET_NOT_FOUND", code: "AP004" },
    { message: "INVALID_FAVORITE", code: "AP500" },
    { message: "INVALID_FAVORITE_COMP", code: "AP501" },
    { message: "INVALID_FAVORITE_MATCH", code: "AP502" },
    { message: "INVALID_PARLAY_QUOTE", code: "APP801" },
    { message: "PARLAY_QUOTE_USED", code: "APP802" },
    { message: "UNAUTHORISED_PARLAY_QUOTE", code: "APP803" },
    { message: "INVALID_PARLAY_PARAM", code: "APP804" },
    { message: "ODD_NOT_AVAILABLE", code: "APO501" },
    { message: "INVALID_QUOTE", code: "APQ301" },
    { message: "QUOTE_USED", code: "APQ302" },
    { message: "UNAUTHORISED_QUOTE", code: "APQ303" },
    { message: "BET_ERROR_DUPLICATE_REFERENCES", code: "APB001" },
    { message: "BET_ERROR_OTHERS", code: "APB999" },
    { message: "INSUFFICIENT_CREDIT", code: "APC01" },
    { message: "OTP_EXPIRED", code: "OTP100" },
    { message: "OTP_INVALID", code: "OTP101" },
    { message: "MISSING_BET_SETTING_IN_LEAGUE", code: "AL001" },
    { message: "SPIDER_TASK_EXIST", code: "SP001" },
  ];
}
